<template>
  <div class="video-play">
    <div class="title" :style="{ 'text-align': direction }">{{ title }}</div>
    <video
      :style="!bigVideo ? { background: '#000' } : ''"
      :preload="preload"
      :poster="videoImg"
      :height="bigVideo ? bigHeight : smallHeight"
      :width="bigVideo ? bigWidth : smallWidth"
      :controls="controls"
      controlslist="nodownload"
    >
      <!-- :autoplay="autoplay" -->
      <source :src="videoSrc" type="video/mp4" />
    </video>
    <div class="even-more" @click="moreBtn()">了解更多>></div>
  </div>
</template>

<script>
import Video from "video.js";
import "video.js/dist/video-js.css";

export default {
  name: "videoPlay",
  props: {
    bigVideo: {
      type: Boolean,
      default: false,
    },
    direction: {
      type: String,
      default: "left",
    },
    title: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    videoSrc: {
      type: String,
      default: "",
    },
    videoImg: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // videoSrc:
      //   "https://yufengtuchuang.oss-cn-beijing.aliyuncs.com/video/1730005870.mp4",
      // // videoSrc: 'require(../../assets/晚霞.mp4)',
      // videoImg:
      //   "https://yufengtuchuang.oss-cn-beijing.aliyuncs.com/banner/10.jpg",
      playStatus: "",
      muteStatus: "",
      isMute: true,
      isPlay: false,
      bigWidth: 0.34375 * document.body.clientWidth, // 设置视频播放器的显示宽度（以像素为单位）
      bigHeight: 0.1953125 * document.body.clientWidth, // 设置视频播放器的显示高度（以像素为单位）
      smallWidth: 0.3109375 * document.body.clientWidth,
      smallHeight: 0.23203125 * document.body.clientWidth,

      preload: "auto", //  建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
      controls: true, // 确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
      autoplay: "",
    };
  },

  methods: {
    moreBtn() {
      if (this.id == 1) {
        this.$router.push("/curriculum");
      } else if (this.id == 2) {
        this.$router.push("/teaching-system");
      } else if (this.id == 3) {
        this.$router.push("/reading-test");
      } else if (this.id == 4) {
        this.$router.push("/reading-agency");
      } else if (this.id == 5) {
        this.$router.push("/magic-classroom");
      } else if (this.id == 6) {
        this.$router.push("/writing");
      } else if (this.id == 7) {
        this.$router.push("/drama");
      } else if (this.id == 8) {
        this.$router.push("/universal-action");
      }
    },
  },

  // 自动播放属性,muted:静音播放
  // autoplay: 'muted',
};
</script>

<style lang="scss" scoped >
.video-play {
  video-bg {
    background: #000;
  }
  .title {
    font-family: SimHei;
    font-size: 0.36rem;
    font-weight: normal;
    font-stretch: normal;
    padding-bottom: 0.12rem;
    letter-spacing: 0px;
    color: #fefdfd;
  }
  .even-more {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -10px;
    width: 1.2rem;
    height: 0.34rem;
    box-shadow: 0.02rem 0.02rem 0.07rem 0px rgba(0, 0, 0, 0.35);
    font-family: MicrosoftYaHei;
    font-size: 0.18rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #050005;
    cursor: pointer;
  }
}
</style>

