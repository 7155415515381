<template>
  <div class="footer-box">
    <div class="main">
      <!-- logo -->
      <div class="logo-box">
        <img src="../../assets/images/icon/logo.png" alt="" />
      </div>

      <!-- 内容 -->
      <div class="content">
        <!-- 媒体矩阵 -->
        <ul class="media">
          <li class="title">媒体矩阵</li>
          <li>
            <span>微信公众号</span>
            <span>今日头条</span>
          </li>
          <li>
            <span>微信小程序</span>
            <span>抖音</span>
          </li>
          <li>
            <span>视频号</span>
            <span>新浪微博</span>
          </li>
          <li>
            <span>小红书</span>
            <span>哔哩哔哩</span>
          </li>
        </ul>

        <!-- 联系方式 -->
        <ul class="contact">
          <li class="title">联系我们</li>
          <li class="choice">
            <img src="../../assets/images/icon/WeChat_gray.png" alt="" />
            <img src="../../assets/images/icon/qq_icon.png" alt="" />
          </li>
          <li class="title">邮箱</li>
          <li class="info">support@duzhe.com</li>
          <li class="title">电话</li>
          <li class="info">4001005353</li>
          <li class="title">地址</li>
          <li class="info">甘肃省兰州市城关区读者大厦</li>
        </ul>

        <!-- 建议 -->
        <div class="suggest">
          <div class="test">
            您对我们的工作有什么建议， 欢迎联系我们， 我们会尽快反馈。
          </div>
          <div class="btn-box" @click="leaveWord()">我要留言</div>
        </div>
      </div>

      <!-- 备案 -->
      <div class="records">
        <div class="number">
          <span @click="toBadge(0)">ICP备案/许可证号：陇ICP备11000599号</span>
          <img src="../../assets/images/icon/badge.png" alt="" />
          <span @click="toBadge(1)">甘公网安备62010202002816号</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {},

  methods: {
    // 去留言
    leaveWord() {
      this.$router.push("leave-word");
    },
    toBadge(e) {
      let list = [
        "https://beian.miit.gov.cn",
        "https://www.beian.gov.cn/portal/registerSystemInfo",
      ];
      window.open(list[e],'_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  box-sizing: border-box;
}
.footer-box {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 400px;
  background-color: #f2f2f2;
  .main {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 1200px;
    padding-top: 60px;

    .logo-box {
      padding-right: 110px;
      img {
        width: 272px;
      }
    }

    // 主要内容
    .content {
      display: flex;

      // 媒体矩阵
      .media {
        display: flex;
        flex-direction: column;
        .title {
          padding-bottom: 0.3rem;
        }
        li {
          list-style: none;
          font-family: PingFangSC;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #333333;
          span {
            display: inline-block;
            width: 140px;
            padding: 0 0 0.18rem 0;
            font-family: PingFangSC;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #333333;
          }
        }
      }

      //   联系方式
      .contact {
        display: flex;
        flex-direction: column;
        .title {
          padding-bottom: 0.06rem;
          list-style: none;
          font-family: PingFangSC;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #333333;
        }
        .info {
          padding-bottom: 0.18rem;
          list-style: none;
          font-family: PingFangSC;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #333333;
        }
        .choice {
          display: flex;
          padding-bottom: 0.18rem;
          list-style: none;
          img {
            margin-right: 0.2rem;
            width: 0.28rem;
          }
        }
      }

      //   建议
      .suggest {
        display: flex;
        flex-direction: column;
        width: 264px;
        margin-left: 60px;
        .test {
          font-family: PingFangSC;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 24px;
          letter-spacing: 0px;
          color: #333333;
        }
        .btn-box {
          margin-top: 0.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 1.6rem;
          height: 0.4rem;
          background-color: #ffffff;
          border-radius: 0.08rem;
          font-family: PingFangSC;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #333333;
          cursor: pointer;
        }
      }
    }

    // 备案
    .records {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      // width: 1200px;
      height: 0.6rem;
      // padding-left: 382px;
      border-top: 1px solid #fff;
      .number {
        font-family: PingFangSC;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #333333;
        display: flex;
        align-items: center;
        img {
          margin: 0 10px 0 20px;
        }
        span {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
