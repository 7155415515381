import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import Video from 'video.js'
import navHeader from './components/navHeader/navHeader.vue'
import Footer from './components/footer/footer.vue'
import { baseURL } from './utils/config'
import 'video.js/dist/video-js.css'
import './utils/rem'
const app = createApp(App)
app.use(router)
app.mount('#app')
app.use(ElementPlus)
app.config.globalProperties.$pitch = { data: 1 }
app.config.globalProperties.getImg = (e) => {
    return baseURL + e
}
app.component(navHeader.name, navHeader)
app.component(Footer.name, Footer)

// app.use(Video)
