<template>
  <div class="nav_header">
    <div class="nav-header">
      <img @click="goIndex()" src="../../assets/images/icon/logo.png" alt="" />
      <div class="nav-box">
        <div
          class="item-box"
          :id="'nav' + index"
          :class="$pitch.data == index ? 'pitch' : ''"
          v-for="(item, index) in navList"
          :key="index"
          @click="navBtn(index)"
        >
          {{ item }}
        </div>
        <div class="more" id="more" v-show="more">
          <span @click="readBtn()">阅读</span>
          <span @click="writingBtn()">写作</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "navHeader",
  data() {
    return {
      navList: ["首页", "产品及服务", "最新资讯", "关于我们"],//, "合作伙伴"
      more: false,
    };
  },
  mounted() {
    // 监听，除了点击自己，点击其他地方将自身隐藏
    document.addEventListener("click", (e) => {
      const contentWrap = document.getElementById("more");
      const contentNav = document.getElementById("nav1");
      if (contentWrap) {
        if (!contentWrap.contains(e.target) && !contentNav.contains(e.target)) {
          this.more = false;
        }
      }
    });
  },
  methods: {
    navBtn(index) {
      this.$pitch.data = index;
      if (index == 1) {
        this.more = true;
      } else {
        this.more = false;
      }
      if (index == 0) {
        this.$router.push("/");
      } else if (index == 2) {
        this.$router.push("/news-list");
      } else if (index == 3) {
        this.$router.push("/about");
      } else if (index == 4) {
        this.$router.push("/partner");
      }
    },

    readBtn() {
      this.more = false;
      this.$router.push("/curriculum");
    },
    writingBtn() {
      this.more = false;
      this.$router.push("/writing");
    },

    // 去首页
    goIndex() {
      this.$router.replace("/");
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  box-sizing: border-box;
}
.nav_header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 70px;
  background-color: #fff;

  .nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1200px;
    height: 70px;
    img {
      margin-top: 0.12rem;
      width: 1.54rem;
      height: 0.37rem;
      cursor: pointer;
    }
    .nav-box {
      position: relative;
      display: flex;
      .item-box {
        display: flex;
        // align-items: flex-end;
        margin-left: 0.3rem;
        // height: 0.5rem;
        padding: 0 0.1rem;
        border-top: 0.07rem solid #fff;
        line-height: 0.8rem;
        font-family: PingFangSC;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 2px;
        color: #333;
        cursor: pointer;
      }
      .more {
        position: absolute;
        top: 54px;
        left: 72px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 160px;
        height: 120px;
        background-color: #ffffff;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        span {
          font-family: PingFangSC;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 48px;
          letter-spacing: 2px;
          color: #333333;
          cursor: pointer;
        }
      }
      .pitch {
        border-top: 0.07rem solid #b5beff;
      }
    }
  }
}
// on
// warning
</style>