<template>
  <div id="wrap" :style="{ height: screenHeight + 'px' }">
    <!-- 导航 -->
    <div class="nav-header">
      <img
        :class="curIndex > 1 ? 'pointer' : ''"
        src="../../assets/images/icon/logo_icon.png"
        alt=""
        @click="goTop()"
      />
      <div class="nav-box">
        <div
          class="item-box"
          :id="'nav' + index"
          :class="pitch == index ? 'pitch' : ''"
          v-for="(item, index) in navList"
          :key="index"
          @click="navBtn(index)"
        >
          {{ item }}
        </div>
        <div class="more" id="more" v-show="more">
          <span @click="readBtn()">阅读</span>
          <span @click="writingBtn()">写作</span>
        </div>
      </div>
    </div>
    <!-- 导航end -->

    <!-- 主要内容 -->
    <div id="main" :style="{ top: nowTop + 'px' }">
      <!-- 右侧序列 -->
      <ul id="pageUl" type="circle">
        <li
          v-for="(item, index) in list"
          :key="index"
          @click="navTo(item, $event)"
          id="pageUlLi1"
          class="pageUlLi"
          :class="item == curIndex ? 'current' : ''"
        >
          <!-- &nbsp; -->
        </li>
      </ul>

      <!-- 第一屏 -->
      <div id="page1" class="page">
        <div class="title-box">
          <img src="../../assets/images/bg_img/title_01.png" alt="" />
        </div>
        <!----------------------- 图标--------------------- -->
        <!----------------------- 图标--------------------- -->
        <div class="icon icon_1">
          <img
            src="../../assets/images/bg_img/index-icon/1316_icon.png"
            alt=""
          />
        </div>
        <div class="icon icon_6">
          <img
            src="../../assets/images/bg_img/index-icon/1318_icon.png"
            alt=""
          />
        </div>
        <div class="icon icon_8">
          <img src="../../assets/images/bg_img/index-icon/1317.png" alt="" />
        </div>
        <div class="icon icon_9">
          <img src="../../assets/images/icon/1314_icon.png" alt="" />
        </div>
        <div class="icon icon_14">
          <img src="../../assets/images/bg_img/index-icon/div_01.png" alt="" />
        </div>
        <!----------------------- 图标--------------------- -->
        <!----------------------- 图标--------------------- -->
        <div class="share-box">
          <img src="../../assets/images/icon/WeChat_icon.png" alt="" />
          <img src="../../assets/images/icon/applet_icon.png" alt="" />
          <img src="../../assets/images/icon/tiktok_icon.png" alt="" />
          <img src="../../assets/images/icon/headlines_icon.png" alt="" />
          <img src="../../assets/images/icon/listen_icon.png" alt="" />
          <img src="../../assets/images/icon/praise_icon.png" alt="" />
        </div>
      </div>

      <!-- 第二屏 -->
      <div id="page2" class="page">
        <videoPlay
          bigVideo
          title="读者新语文音视频课程"
          class="small-right-video"
          id="1"
          :videoSrc="videoData[0].videoSrc"
          :videoImg="videoData[0].videoImg"
        ></videoPlay>
        <!-- 侧边子导航 -->
        <div class="usb-nav">
          <div class="tag-box">
            <span>阅读</span>
            <span class="pinyin">yuedu</span>
          </div>
          <div class="more-btn">
            <img src="../../assets/images/icon/more_icon.png" alt="" />
          </div>
        </div>
      </div>

      <!-- 第三屏 -->
      <div id="page3" class="page">
        <videoPlay
          bigVideo
          direction="right"
          title="阅读写作教育体系"
          class="small-left-video"
          id="2"
          :videoSrc="videoData[1].videoSrc"
          :videoImg="videoData[1].videoImg"
        ></videoPlay>
        <!-- 侧边子导航 -->
        <div class="usb-nav">
          <div class="tag-box">
            <span>阅读</span>
            <span class="pinyin">yuedu</span>
          </div>
          <div class="more-btn">
            <img src="../../assets/images/icon/more_icon.png" alt="" />
          </div>
        </div>
      </div>

      <!-- 第四屏 -->
      <div id="page4" class="page">
        <videoPlay
          bigVideo
          title="阅读力测试"
          class="small-right-video"
          id="3"
          :videoSrc="videoData[2].videoSrc"
          :videoImg="videoData[2].videoImg"
        ></videoPlay>
        <!-- 侧边子导航 -->
        <div class="usb-nav on-right" style="padding-top: 1.4rem">
          <div class="tag-box">
            <span>阅读</span>
            <span class="pinyin">yuedu</span>
          </div>
          <div class="more-btn">
            <img src="../../assets/images/icon/more_icon.png" alt="" />
          </div>
        </div>
      </div>

      <!-- 第五屏 -->
      <div id="page5" class="page">
        <videoPlay
          bigVideo
          direction="right"
          title="长尾巴月读社"
          class="small-left-video"
          id="4"
          :videoSrc="videoData[3].videoSrc"
          :videoImg="videoData[3].videoImg"
        ></videoPlay>
        <!-- 侧边子导航 -->
        <div class="usb-nav">
          <div class="tag-box">
            <span>阅读</span>
            <span class="pinyin">yuedu</span>
          </div>
          <div class="more-btn">
            <img src="../../assets/images/icon/more_icon.png" alt="" />
          </div>
        </div>
      </div>

      <!-- 第六屏 -->
      <div id="page6" class="page">
        <videoPlay
          bigVideo
          title="魔法写作教室"
          class="big-right-video"
          id="5"
          :videoSrc="videoData[4].videoSrc"
          :videoImg="videoData[4].videoImg"
        ></videoPlay>
        <!-- 侧边子导航 -->
        <div class="usb-nav">
          <div class="tag-box">
            <span>写作</span>
            <span class="pinyin">xiezuo</span>
          </div>
          <div class="more-btn">
            <img src="../../assets/images/icon/more_icon.png" alt="" />
          </div>
        </div>
      </div>

      <!-- 第七屏 -->
      <div id="page7" class="page">
        <videoPlay
          bigVideo
          direction="right"
          title="创意写作改稿会"
          class="big-left-video"
          id="6"
          :videoSrc="videoData[5].videoSrc"
          :videoImg="videoData[5].videoImg"
        ></videoPlay>
        <!-- 侧边子导航 -->
        <div class="usb-nav on-right">
          <div class="tag-box">
            <span>写作</span>
            <span class="pinyin">xiezuo</span>
          </div>
          <div class="more-btn">
            <img src="../../assets/images/icon/more_icon.png" alt="" />
          </div>
        </div>
      </div>

      <!-- 第八屏 -->
      <div id="page8" class="page">
        <videoPlay
          bigVideo
          title="儿童戏剧课"
          class="big-right-video"
          id="7"
          :videoSrc="videoData[6].videoSrc"
          :videoImg="videoData[6].videoImg"
        ></videoPlay>
        <!-- 侧边子导航 -->
        <div class="usb-nav">
          <div class="tag-box">
            <span>写作</span>
            <span class="pinyin">xiezuo</span>
          </div>
          <div class="more-btn">
            <img src="../../assets/images/icon/more_icon.png" alt="" />
          </div>
        </div>
      </div>

      <!-- 第九屏 -->
      <div id="page9" class="page">
        <videoPlay
          bigVideo
          direction="right"
          title="全民阅读推广活动"
          class="big-left-video"
          id="8"
          :videoSrc="videoData[7].videoSrc"
          :videoImg="videoData[7].videoImg"
        ></videoPlay>
        <!-- 侧边子导航 -->
        <div class="usb-nav">
          <div class="tag-box">
            <span>阅读</span>
            <span class="pinyin">yuedu</span>
          </div>
          <div class="more-btn">
            <img src="../../assets/images/icon/more_icon.png" alt="" />
          </div>
        </div>
        <!-- ICP备案 -->
        <div class="badge_box">
          <span @click="toBadge(0)">ICP备案/许可证号：陇ICP备11000599号</span>
          <img src="../../assets/images/icon/badge.png" alt="" />
          <span @click="toBadge(1)">甘公网安备62010202002816号</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import videoPlay from "../../components/videoPlay/videoPlay.vue";
export default {
  name: "index",
  components: {
    videoPlay,
  },
  data() {
    return {
      navList: ["首页", "产品及服务", "最新资讯", "关于我们"], //, "合作伙伴"
      pitch: 0,
      list: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
      screenWeight: 0, // 屏幕宽度
      screenHeight: 0, // 屏幕高度
      index: 1, // 用于判断翻页
      curIndex: 1, // 当前页的index
      startTime: 0, // 翻屏起始时间
      endTime: 0, // 上一次翻屏结束时间
      clickTime: 0,
      skipTime: 0,
      nowTop: 0, // 翻屏后top的位置
      pageNum: 0, // 一共有多少页
      main: Object,
      obj: Object,
      more: false,
      videoData: [
        {
          videoSrc: require("../../assets/video/course1.mp4"),
          videoImg: require("../../assets/cover/cover_01.jpg"),
        },
        {
          videoSrc: require("../../assets/video/course2.mp4"),
          videoImg: require("../../assets/cover/cover_02.jpg"),
        },
        {
          videoSrc: require("../../assets/video/course3.mp4"),
          videoImg: require("../../assets/cover/cover_03.jpg"),
        },
        {
          videoSrc: require("../../assets/video/course4.mp4"),
          videoImg: require("../../assets/cover/cover_04.jpg"),
        },
        {
          videoSrc: require("../../assets/video/course5.mp4"),
          videoImg: require("../../assets/cover/cover_06.jpg"),
        },
        {
          videoSrc: require("../../assets/video/course6.mp4"),
          videoImg: require("../../assets/cover/cover_06.jpg"),
        },
        {
          videoSrc: require("../../assets/video/course7.mp4"),
          videoImg: require("../../assets/cover/cover_07.jpg"),
        },
        {
          videoSrc: require("../../assets/video/course8.mp4"),
          videoImg: require("../../assets/cover/cover_08.jpg"),
        },
      ],
    };
  },
  created() {
    console.log(document.body.clientWidth);
    // videoWidth=0.34375*document.body.clientWidth
  },
  mounted() {
    this.screenWeight = document.documentElement.clientWidth;
    this.screenHeight = document.documentElement.clientHeight;
    this.main = document.getElementById("main");
    this.obj = document.getElementsByTagName("div");
    for (let i = 0; i < this.obj.length; i++) {
      if (this.obj[i].className == "page") {
        this.obj[i].style.height = this.screenHeight + "px";
      }
    }

    this.pageNum = document.querySelectorAll(".page").length;

    // 浏览器兼容

    if (navigator.userAgent.toLowerCase().indexOf("firefox") != -1) {
      document.addEventListener("DOMMouseScroll", this.scrollFun, false);
    } else if (document.addEventListener) {
      document.addEventListener("mousewheel", this.scrollFun, false);
    } else if (document.attachEvent) {
      document.attachEvent("onmousewheel", this.scrollFun);
    } else {
      document.onmousewheel = this.scrollFun;
    }
    // 监听，除了点击自己，点击其他地方将自身隐藏
    document.addEventListener("click", (e) => {
      const contentWrap = document.getElementById("more");
      const contentNav = document.getElementById("nav1");
      if (contentWrap) {
        if (!contentWrap.contains(e.target) && !contentNav.contains(e.target)) {
          this.more = false;
        }
      }
    });
  },

  methods: {
    navTo(a) {
      this.clickTime = new Date().getTime();
      if (this.clickTime - this.skipTime > 1500) {
        this.index = a;
        this.toPage(a);
        this.active = a;
        this.skipTime = new Date().getTime();
      }
    },

    // 去顶部
    goTop() {
      if (this.index != 1) {
        this.index = 1;
        this.active = 1;
        this.toPage(1);
      }
    },

    scrollFun(event) {
      this.startTime = new Date().getTime();
      // mousewheel事件中的 “event.wheelDelta” 属性值：返回的如果是正值说明滚轮是向上滚动
      // DOMMouseScroll事件中的 “event.detail” 属性值：返回的如果是负值说明滚轮是向上滚动
      let delta = event.detail || -event.wheelDelta;
      // 如果当前滚动开始时间和上次滚动结束时间的差值小于1.5s，则不执行翻页动作，这样做是为了实现类似节流的效果
      if (this.startTime - this.endTime > 1500) {
        if (
          delta > 0 &&
          parseInt(this.main.offsetTop) >=
            -(this.screenHeight * (this.pageNum - 2))
        ) {
          // 向下滚动

          this.index++;

          this.toPage(this.index);
        } else if (delta < 0 && parseInt(this.main.offsetTop) < 0) {
          // 向上滚动
          this.index--;
          this.toPage(this.index);
        }

        // 本次翻页结束，记录结束时间，用于下次判断

        this.endTime = new Date().getTime();
      }
    },

    // 翻页

    toPage(index) {
      this.more = false;
      if (index != this.curIndex) {
        let delta = index - this.curIndex;
        this.nowTop = this.nowTop - delta * this.screenHeight;
        setTimeout(() => {
          this.curIndex = index;
        }, 300);
      }
    },

    navBtn(index) {
      this.$pitch.data = index;
      this.pitch = index;
      if (index == 1) {
        this.more = true;
      } else {
        this.more = false;
      }
      if (index == 2) {
        this.$router.push("/news-list");
      } else if (index == 3) {
        this.$router.push("/about");
      } else if (index == 4) {
        this.$router.push("/partner");
      }
    },
    readBtn() {
      this.more = false;
      this.$router.push("/curriculum");
    },
    writingBtn() {
      this.more = false;
      this.$router.push("/writing");
    },
    toBadge(e) {
      let list = [
        "https://beian.miit.gov.cn",
        "https://www.beian.gov.cn/portal/registerSystemInfo",
      ];
      window.open(list[e],'_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
html,
body {
  width: 100%;
  height: 100vh;
}

body,
ul,
li,
a,
p,
div {
  /*慎删*/
  box-sizing: border-box;
  padding: 0px;

  margin: 0px;
}

#wrap {
  overflow: hidden;

  width: 100%;
}

#main {
  position: relative;

  transition: top 1.5s;
}

.page {
  /*谨删*/
  position: relative;
  width: 100%;

  margin: 0;
}

#pageUl {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  right: 0.34rem;
  bottom: calc(50% - 1rem);
  z-index: 99;
  li {
    margin-top: 0.1rem;
    width: 0.16rem;
    height: 0.16rem;
    list-style: none;
    background: url("../../assets/images/icon/marker_01.png");
    background-size: 0.16rem 0.16rem;
  }
  .current {
    width: 0.5rem;
    height: 0.5rem;
    list-style: none;
    background: url("../../assets/images/icon/marker_02.png");
    background-size: 0.5rem 0.5rem;
  }
}

.active {
  color: blue;
}
.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 0.5rem;
  padding: 0 0.76rem;
  img {
    margin-top: 0.12rem;
    width: 1.54rem;
    height: 0.37rem;
  }
  .pointer {
    cursor: pointer;
  }
  .nav-box {
    position: relative;
    display: flex;
    .item-box {
      display: flex;
      align-items: flex-end;
      margin-left: 0.3rem;
      height: 0.5rem;
      padding: 0 0.1rem;
      //   line-height: 0.8rem;
      font-family: "微软雅黑";
      font-size: 19px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 1px;
      color: #fefbfb;
      cursor: pointer;
    }
    .more {
      position: absolute;
      top: 54px;
      left: 72px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 160px;
      height: 120px;
      background-color: #ffffff;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      span {
        font-family: PingFangSC;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 48px;
        letter-spacing: 2px;
        color: #333333;
        cursor: pointer;
      }
    }
    .pitch {
      border-top: 0.07rem solid #f9f5f5;
    }
  }
}

// 第一屏
#page1 {
  // background: url("../../assets/images/bg_img/banner_01.jpg");
  background: url("../../assets/images/bg_img/banner_77.jpg");
  background-size: 100% 100%;
  .title-box {
    padding: 6.3rem 0 0 2.4rem;
    img {
      width: 5.6rem;
    }
  }
  .icon_1 {
    position: absolute;
    top: 731px;
    left: 800px;
    img {
      width: 186px;
      height: 147px;
    }
  }
  .icon_6 {
    position: absolute;
    top: 154px;
    left: 940px;
    img {
      width: 100px;
      height: 121px;
    }
  }
  .icon_8 {
    position: absolute;
    top: 51px;
    right: 611px;
    img {
      width: 92px;
      height: 115px;
    }
  }
  .icon_9 {
    position: absolute;
    top: 104px;
    right: 439px;
    img {
      width: 80px;
      height: 100px;
    }
  }
  .icon_14 {
    position: absolute;
    top: 756px;
    right: 137px;

    img {
      width: 246px;
      height: 160px;
      -webkit-transition: all 1s cubic-bezier(0.02, 0.01, 0.47, 1);

      transition: all 1s cubic-bezier(0.02, 0.01, 0.47, 1);
    }
  }
  .icon:hover {
    animation: rotate-anim 0.6s;
    // box-shadow: 0 16px 32px 0 rgba(48, 55, 66, 0.15);

    transform: translate(0, -5px);
    transform: scale(1.2);
    transition-delay: 0s !important;
  }
  @keyframes rotate-anim {
    // 0% {
    //   opacity: 1;
    // }
    // 50% {
    //   opacity: 0.8;
    // }
    // 100% {
    //   opacity: 1;
    // }
  }
  .share-box {
    position: absolute;
    left: 2.76rem;
    bottom: 0.86rem;
    display: flex;
    align-items: center;
    width: 2.3rem;
    padding-top: 0.14rem;
    border-top: 2px solid #faefed;
    img {
      margin-right: 0.12rem;
      width: 0.28rem;
      height: 0.28rem;
      cursor: pointer;
    }
  }
}
// 第二屏
#page2 {
  background: url("../../assets/images/bg_img/banner_02.jpg");
  background-size: 100% 100%;
  .small-right-video {
    position: absolute;
    top: 2.3rem;
    right: 1.75rem;
  }
}
// 第三屏
#page3 {
  background: url("../../assets/images/bg_img/banner_03.jpg");
  background-size: 100% 100%;
  .small-left-video {
    position: absolute;
    top: 2.6rem;
    left: 2.3rem;
  }
}
// 第四屏
#page4 {
  background: url("../../assets/images/bg_img/banner_04.jpg");
  background-size: 100% 100%;
  .small-right-video {
    position: absolute;
    top: 2.1rem;
    right: 1.8rem;
  }
}
// 第五屏
#page5 {
  background: url("../../assets/images/bg_img/banner_05.jpg");
  background-size: 100% 100%;
  .small-left-video {
    position: absolute;
    top: 2.7rem;
    left: 2.3rem;
  }
}
// 第六屏
#page6 {
  background: url("../../assets/images/bg_img/banner_06.jpg");
  background-size: 100% 100%;
}
// 第七屏
#page7 {
  background: url("../../assets/images/bg_img/banner_07.jpg");
  background-size: 100% 100%;
}
// 第八屏
#page8 {
  background: url("../../assets/images/bg_img/banner_08.jpg");
  background-size: 100% 100%;
}
// 第九屏
#page9 {
  background: url("../../assets/images/bg_img/banner_09.jpg");
  background-size: 100% 100%;
  position: relative;
  .badge_box {
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    color: #ffffff;
    font-size: 13px;
    display: flex;
    align-items: center;
    img {
      margin: 0 10px 0 20px;
    }
    span {
      cursor: pointer;
    }
  }
}
.big-right-video {
  position: absolute;
  top: 2.57rem;
  right: 1.8rem;
}
.big-left-video {
  position: absolute;
  top: 2.48rem;
  left: 1.84rem;
}

//
.usb-nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding-top: 1.64rem;
  width: 1.54rem;
  text-align: center;
  .tag-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 1.54rem;
    height: 0.97rem;
    border-radius: 0 0.48rem 0.48rem 0;
    background-color: #c5010b;
    font-family: MicrosoftYaHei;
    font-size: 0.32rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #fcfafa;
    span {
      text-align: center;
    }
    .pinyin {
      font-size: 22px;
    }
  }
  .more-btn {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 1.54rem;
    height: 0.82rem;
    padding-top: 0.2rem;
    text-align: center;
    img {
      vertical-align: middle;
      width: 0.62rem;
      height: 0.62rem;
      cursor: pointer;
    }
  }
}
.on-right {
  position: absolute;
  right: 0;
  top: 0;
  .tag-box {
    border-radius: 0.48rem 0 0 0.48rem;
  }
}
</style>
