import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
const routes = [
    {
        path: '/',  //首页
        name: 'index',
        component: () => import('../views/index/index.vue')
    },
    {
        path: '/news-list',  //资讯列表
        name: 'newsList',
        component: () => import('../views/news/newsList/newsList.vue')
    },
    {
        path: '/news-details',  //资讯详情
        name: 'newsDetails',
        component: () => import('../views/news/newsDetails/newsDetails.vue')
    },
    {
        path: '/curriculum',  //音视频课程
        name: 'curriculum',
        component: () => import('../views/product/curriculum/curriculum.vue')
    },
    {
        path: '/teaching-system',  //阅读写作教学体系
        name: 'teachingSystem',
        component: () => import('../views/product/teachingSystem/teachingSystem.vue')
    },
    {
        path: '/reading-agency',  //长尾巴阅读社
        name: 'readingAgency',
        component: () => import('../views/product/readingAgency/readingAgency.vue')
    },
    {
        path: '/universal-action',  //全民阅读行动
        name: 'universalAction',
        component: () => import('../views/product/universalAction/universalAction.vue')
    },
    {
        path: '/reading-test',  //阅读能力测试
        name: 'readingTest',
        component: () => import('../views/readingTest/readingTest/readingTest.vue')
    },
    {
        path: '/test-details',  //测试详情
        name: 'testDetails',
        component: () => import('../views/readingTest/testDetails/testDetails.vue')
    }
    ,
    {
        path: '/test-outcome',  //测试结果
        name: 'testOutcome',
        component: () => import('../views/readingTest/testOutcome/testOutcome.vue')
    }
    ,
    {
        path: '/leave-word',  //留言
        name: 'leaveWord',
        component: () => import('../views/leaveWord/leaveWord.vue')
    },
    {
        path: '/about',  //关于我们
        name: 'about',
        component: () => import('../views/about/about.vue')
    },
    {
        path: '/partner',  //合作伙伴
        name: 'partner',
        component: () => import('../views/partner/partner.vue')
    },
    {
        path: '/magic-classroom',  //魔法写作教室
        name: 'magicClassroom',
        component: () => import('../views/teaching/magicClassroom/magicClassroom.vue')
    },
    {
        path: '/writing',  //创意写作改稿会
        name: 'writing',
        component: () => import('../views/teaching/writing/writing.vue')
    },
    {
        path: '/drama',  //儿童戏剧课
        name: 'drama',
        component: () => import('../views/teaching/drama/drama.vue')
    }
]
const routerHash = createWebHashHistory()
const router = createRouter({
    history: routerHash,
    routes,
    scrollBehavior(to, from, savedPosition) {
        // 解决路由跳转后 会滚动到底部
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },

})
export default router

