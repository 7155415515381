<template>
  <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
  <!-- <Index></Index> -->
  <router-view></router-view>
</template>

<script>
import Index from "./views/index/index.vue";

export default {
  name: "App",
  components: {
    Index,
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
@media (max-width: 1680px) {
  .code {
    display: none;
  }
  #more {
    top: 44px;
    left: 62px;
  }
}
</style>
